/**
 * Bio component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import {StaticQuery, graphql} from "gatsby"
import Image from "gatsby-image"
import styled from "styled-components"

import {rhythm} from "../utils/typography"

function Bio() {
    return (<StaticQuery
            query={bioQuery}
            render={data => {
                const {author, social} = data.site.siteMetadata
                return (<Container>
                        <Image
                            fixed={data.avatar.childImageSharp.fixed}
                            alt={author}
                            style={{
                                marginRight: rhythm(1 / 2), marginBottom: 0, minWidth: 50, borderRadius: `100%`,
                            }}
                            imgStyle={{
                                borderRadius: `50%`,
                            }}
                        />
                        <p>
                            Director of Engineering and international speaker in Melbourne, Australia.
                            <br/>
                            <a style={{
                                marginRight: rhythm(1 / 2),
                                fontSize: "15px"
                            }} href={`https://www.linkedin.com/in/prakritimateti`}>
                                LinkedIn
                            </a>
                            {` `}
                            <a style={{
                                marginRight: rhythm(1 / 2),
                                fontSize: "15px"
                            }} href={`https://twitter.com/${social.twitter}`}>
                                Twitter
                            </a>
                            {` `}
                            <a style={{
                                marginRight: rhythm(1 / 2),
                                fontSize: "15px"
                            }} href={`https://medium.com/@itirkarp`}>
                                Medium
                            </a>
                        </p>
                    </Container>)
            }}
        />)
}

const bioQuery = graphql`
  query BioQuery {
    avatar: file(absolutePath: { regex: "/profile-pic.JPG/" }) {
      childImageSharp {
        fixed(width: 50, height: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        author
        social {
          twitter
        }
      }
    }
  }
`

const Container = styled.div`
  display: flex;
`

export default Bio
